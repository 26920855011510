import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { ContactComponent } from '../contact/contact-component';

@Component({
    selector: 'app-top-bar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    imports: [MatSlideToggleModule, ReactiveFormsModule, MatButtonModule, MatIconModule, MatMenuModule],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: NavbarComponent
        }
    ]
})
export class NavbarComponent implements OnInit, OnDestroy, ControlValueAccessor {
  private sub = new Subscription();
  private logoSuffix = 'logo';
  public darkModeControl = new FormControl(false);
  public mobileLogoUrl = '../../../assets/image-svg/logo-mobile.svg';
  public hideLogoName: boolean = true;

  constructor(
    private router: Router,
    private readonly dialog: MatDialog,
    private responsive: BreakpointObserver
  ) {
    this.sub.add(this.darkModeControl.valueChanges.subscribe((val) => (this.logoSuffix = val ? 'logo-dark' : 'logo')));
  }
  public get logoUrl(): string {
    return `../../../assets/image-svg/${this.logoSuffix}.svg`;
  }

  public get isDarkMode(): boolean {
    return !this.darkModeControl.value;
  }

  ngOnInit(): void {
    this.sub.add(
      this.responsive
        .observe([Breakpoints.HandsetPortrait, Breakpoints.TabletPortrait])
        .subscribe((result) => (this.hideLogoName = result.matches))
    );
  }

  goToCustomConfiguration(): void {
    this.router.navigateByUrl('/configuration');
  }

  switchThemeMode(): void {
    this.darkModeControl.setValue(!this.darkModeControl.value);
  }
  openContactForm(): void {
    this.dialog.open(ContactComponent, { autoFocus: false });
  }

  registerOnChange(onChange: any): void {
    this.sub.add(this.darkModeControl.valueChanges.subscribe(onChange));
  }

  writeValue(value: any): void {
    this.darkModeControl.setValue(value);
  }

  registerOnTouched(fn: any): void {}

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
