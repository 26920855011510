import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogClose, MatDialogModule } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { DatePipe } from '@angular/common';
import { RELEASE_NOTES, ReleaseNote } from './release-notes';

@Component({
  selector: 'app-release-notes-modal',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DialogComponent, MatButtonModule, MatDialogModule, MatDialogClose, DatePipe]
})
export class ReleaseNotesComponent {
  protected readonly notes: ReleaseNote[] = RELEASE_NOTES;
}
