import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/shared/services/api.service';
import { iContact } from 'src/app/core/models/contact.model';
import { DialogComponent } from '../dialog/dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { emailPattern } from 'src/app/modules/config/constants/patterns';
import { ContactFormOption } from 'src/app/core/models/contact-form-option.type';

@Component({
    selector: 'app-contact',
    templateUrl: './contact-component.html',
    styleUrls: ['./contact-component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        DialogComponent,
        MatRadioModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatDialogClose,
        NgIf
    ]
})
export class ContactComponent implements OnInit {
  contactForm!: FormGroup;
  public options: ContactFormOption[] = [
    {
      value: 1,
      label: '1 - Very bad'
    },
    {
      value: 2,
      label: '2 - Bad'
    },
    {
      value: 3,
      label: '3 - Ok'
    },
    {
      value: 4,
      label: '4 - Good'
    },
    {
      value: 5,
      label: '5 - Very good'
    }
  ];

  constructor(
    private dialogRef: MatDialogRef<ContactComponent>,
    private fb: FormBuilder,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.createForms();
  }

  createForms(): void {
    this.contactForm = this.fb.group({
      email: ['', Validators.pattern(emailPattern)],
      note: [3],
      description: ['']
    });
  }

  sendData(): void {
    this.contactForm.markAllAsTouched();
    this.contactForm.updateValueAndValidity();
    if (this.contactForm.valid) {
      const contactData: iContact = {
        ...this.contactForm.value
      };
      this.apiService.postFeedback(contactData).subscribe(() => {
        this.dialogRef.close();
        alert('Contact data sent successfully');
      });
    }
  }
}
