import { bootstrapApplication, BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient } from '@angular/common/http';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { APP_ROUTES } from './app/routes';
import { API_URL_PREFIX } from './app/shared/utils/injection-token';
import { environment } from './environments/environment';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

bootstrapApplication(AppComponent, {
  providers: [
    provideClientHydration(),
    importProvidersFrom(BrowserModule),
    importProvidersFrom(BrowserAnimationsModule),
    provideHttpClient(),
    provideRouter(APP_ROUTES, withComponentInputBinding()),
    { provide: API_URL_PREFIX, useValue: environment.apiUrl },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { verticalPosition: 'top', horizontalPosition: 'right' } }
  ]
}).catch(console.error);
