import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ThemeService } from './shared/services/theme.service';
import { MatDialog } from '@angular/material/dialog';
import { ContactComponent } from './shared/components/contact/contact-component';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from './shared/components/footer/footer.component';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { MatButtonModule } from '@angular/material/button';
import { ThemeMode } from './core/enums/theme-mode';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [RouterOutlet, FooterComponent, NavbarComponent, ReactiveFormsModule, MatIconModule, MatButtonModule]
})
export class AppComponent implements OnInit {
  private readonly DARK_CLASS_NAME = 'theme-dark';
  darkModeControl = new FormControl(false);
  @HostBinding('class') className = '';

  constructor(
    private readonly overlay: OverlayContainer,
    private readonly themeService: ThemeService,
    private readonly dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry
      .addSvgIcon(`infinity`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/image-svg/infinity-thin.svg'))
      .addSvgIcon(
        `infinity_bold`,
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/image-svg/infinity-bold.svg')
      );
  }

  ngOnInit(): void {
    this.darkModeControl.valueChanges.subscribe((darkMode) => {
      this.themeService.saveNewMode(darkMode ? ThemeMode.DARK : ThemeMode.LIGHT);
      this.className = darkMode ? this.DARK_CLASS_NAME : '';
      if (darkMode) {
        this.overlay.getContainerElement().classList.add(this.DARK_CLASS_NAME);
      } else {
        this.overlay.getContainerElement().classList.remove(this.DARK_CLASS_NAME);
      }
    });

    this.darkModeControl.setValue(this.themeService.getMode() === ThemeMode.DARK);
  }

  openContactForm(): void {
    this.dialog.open(ContactComponent, { autoFocus: false });
  }
}
