export interface ReleaseNote {
  version: string;
  date: Date;
  systemRelated: string[];
  gameRelated: string[];
  nextInLine: string[];
}

export const RELEASE_NOTES: ReleaseNote[] = [
  {
    version: '2.3.1',
    date: new Date('2025-02-11'),
    systemRelated: ['Maintenance updates'],
    gameRelated: [],
    nextInLine: [
      'New Timer feature',
      'Titles management component - smooth and pleasant management for games with many titles/topics'
    ]
  },
  {
    version: '2.3.0',
    date: new Date('2025-01-28'),
    systemRelated: ['Invite players via QR code', 'History file improvements'],
    gameRelated: ['Edit my vote - new button for players', 'Minor bug fixing'],
    nextInLine: [
      'New Timer feature',
      'Titles management component - smooth management for games with many titles/topics'
    ]
  },
  {
    version: '2.2.0',
    date: new Date('2024-05-08'),
    systemRelated: ['Brand new UI & UX design for mobile devices'],
    gameRelated: ['UI and UX improvements', 'Minor bug fixing'],
    nextInLine: ['New Timer feature', 'Voting title component - more smooth management for multi titles']
  },
  {
    version: '2.1.1',
    date: new Date('2024-04-10'),
    systemRelated: [],
    gameRelated: ['Bug fixing'],
    nextInLine: ['New Timer feature', 'Voting title component - more smooth management for multi titles']
  },
  {
    version: '2.1.0',
    date: new Date('2024-03-14'),
    systemRelated: ['Dark mode - color improvements'],
    gameRelated: ['Summary charts - new design'],
    nextInLine: ['New Timer feature', 'Voting title component - more smooth management for multi titles']
  },
  {
    version: '2.0.0',
    date: new Date('2024-02-18'),
    systemRelated: ['New UI & UX design', 'Table name setting'],
    gameRelated: ['Timer - temporary unavailable', 'Summary charts - temporary unavailable'],
    nextInLine: [
      'New Timer',
      'Summary charts - new design',
      'Dark mode - color improvements',
      'Voting title component - more smooth management for multi titles'
    ]
  },
  {
    version: '1.1.1',
    date: new Date('2023-11-11'),
    systemRelated: ['Bug fixes'],
    gameRelated: [],
    nextInLine: []
  },
  {
    version: '1.1.0',
    date: new Date('2023-11-02'),
    systemRelated: ['Added Dark mode compatibility', 'Editing avatars', 'Release notes'],
    gameRelated: [
      'Adding title for the estimation topic',
      'Removing players from the table',
      'Downloading table history',
      'Adding Timer'
    ],
    nextInLine: []
  },
  {
    version: '1.0.2',
    date: new Date('2023-10-17'),
    systemRelated: ['Bug fixes'],
    gameRelated: [],
    nextInLine: []
  },
  {
    version: '1.0.1',
    date: new Date('2023-09-25'),
    systemRelated: ['Extending characters limit in Users and Dimensions fields', 'Bug fixes'],
    gameRelated: [],
    nextInLine: []
  }
];
