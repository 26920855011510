<app-dialog title="Release notes">
  @for (note of notes; track note.version) {
    <h3>Release {{ note.version }}</h3>
    <p class="release-date">Date: {{ note.date | date: 'dd MMMM yyyy' }}</p>
    <p>System related:</p>
    <ul>
      @for (systemNote of note.systemRelated; track systemNote) {
        <li>{{ systemNote }}</li>
      } @empty {
        <li>NA</li>
      }
    </ul>
    <p class="release-chapter">Game related changes:</p>
    <ul>
      @for (gameNote of note.gameRelated; track gameNote) {
        <li>{{ gameNote }}</li>
      } @empty {
        <li>NA</li>
      }
    </ul>

    @if (note.nextInLine.length) {
      <p class="release-chapter">Next in line:</p>
      <ul>
        @for (nextInLineNote of note.nextInLine; track nextInLineNote) {
          <li>{{ nextInLineNote }}</li>
        }
      </ul>
    }
  }

  <h3>Release 1.0.0</h3>
  <p class="release-date">Date: 12 September 2023</p>
  <p>First release</p>

  <ng-container actions>
    <button mat-button class="stroke-button" mat-dialog-close>Close</button>
  </ng-container>
</app-dialog>
