import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReleaseNotesComponent } from '../release-notes/release-notes.component';
import { RELEASE_NOTES } from '../release-notes/release-notes';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class FooterComponent {
  protected readonly version: string = RELEASE_NOTES[0].version;

  constructor(private readonly dialog: MatDialog) {}

  showReleaseNotes(): void {
    this.dialog.open(ReleaseNotesComponent, { autoFocus: false });
  }
}
